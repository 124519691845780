import SEO from "../SEO";
import "../Components/ProjectsPage/ProjectsPage.css";
import { useEffect, useState } from "react";
import ProjectsPageItems from "../Components/ProjectsPage/ProjectsPageItems";
import axios from "axios";
import Loading from "../Components/Universal/SingleElements/Loading/Loading";
import ScrollTop from "../Components/Universal/SingleFunctions/ScrollTop";
import Circle from "../Components/Universal/Svgs/Circle";

function Projects() {
  const [allCat, setAllCat] = useState();
  const [loaded, setLoaded] = useState(false);
  const [selectedCat, setSelectedCat] = useState("");

  useEffect(() => {
    const catList = [];
    const postData = {
      content_type: "singleProject",
      select: "sys.id,fields.category",
    };
    axios
      .post("https://hyphencreative.co.uk/contentful/posts", postData)
      .then((entry) => {
        entry.data.items.forEach((item) => {
          if (!catList.find((e) => e.name === item.fields.category[0])) {
            catList.push({
              name: item.fields.category[0],
              id: item.sys.id,
            });
          }
        });
        setAllCat(catList);
        setLoaded(true);
        ScrollTop();
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  function catChange(e) {
    if (e.target.value === "All Projects") {
      setSelectedCat("");
    } else {
      setSelectedCat(e.target.value);
    }
  }

  if (loaded) {
    return (
      <div>
        <SEO
          title="Hyphen Creative | Projects | Web Design • Development"
          description="Based in Derby, Hyphen Creative cover a wide range of projects from web design to development."
          keywords="Web Design, Development, Hyphen Creative"
        />
        <section id="projects-hero">
          <div className="container-lg">
            <div className="projects-hero">
              <h1 className="bold">
                <span className="light">
                  We're a small creative <br />
                  agency
                </span>{" "}
                with{" "}
                <span className="blue-circle">
                  <Circle stroke="5" />
                  big
                </span>{" "}
                agency ideas
              </h1>
              <div className="p-categories-options">
                <select
                  name="project-categories-select"
                  onChange={(e) => catChange(e)}
                >
                  <option name="All">All Projects</option>
                  {allCat.map((cat) => (
                    <option key={cat.id} name={cat.name}>
                      {cat.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </section>
        <section id="news-items">
          <div className="container-lg">
            <ProjectsPageItems cat={selectedCat} />
          </div>
        </section>
      </div>
    );
  } else {
    return (
      <div>
        <SEO
          title="Projects"
          description="Hyphen Creative regularly update our blog to bring you the latest industry news and design trends. Derby, Nottingham, Sheffield & Leicester."
          keywords="Web, Design, Development, News, Blog, Tech"
        />
        <section id="projects-hero">
          <div className="container-lg">
            <div className="projects-hero">
              <h1 className="bold">
                <span className="light">
                  We're a small creative <br />
                  agency
                </span>{" "}
                with{" "}
                <span className="blue-circle">
                  <Circle stroke="8" />
                  big
                </span>{" "}
                agency ideas
              </h1>
            </div>
          </div>
        </section>
        <Loading />
      </div>
    );
  }
}

export default Projects;
