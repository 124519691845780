import "../Components/ContactPage/ContactPage.css";
import { useState, useRef, useEffect } from "react";
import axios from "axios";
import SEO from "../SEO";
import ShopifyPartner from "../Components/ContactPage/ContactPageImages/Shopify-Partner.svg";
import Top3 from "../Components/ContactPage/ContactPageImages/Top-3-Web-Designer.webp";
import BreakingBad from "../Components/ContactPage/ContactPageImages/Breaking-Bad.gif";
import WeGotYou from "../Components/ContactPage/ContactPageImages/contact-we-got-you.svg";
import Arrow from "../Components/ContactPage/ContactPageImages/right-arrow.svg";

function Contact() {
  const formRes = useRef();
  //ROW 1
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  //ROW 2
  const [need, setNeed] = useState("Web & Ecommerce");
  const [brief, setBrief] = useState("");
  //ROW 3
  const [site, setSite] = useState("");
  const [improve, setImprove] = useState("");
  //ROW 4
  const [siteType, setSiteType] = useState("One-page website");
  const [budget, setBudget] = useState("£0-500");
  //ROW 5
  const [message, setMessage] = useState("No message.");
  //ROW 6
  const [biscuits, setBiscuits] = useState("no");

  //CONDITIONAL
  const setConditionalNeed = (e) => {
    const conFields = document.querySelectorAll(".form-row-conditional > div");
    const selected = e.target.value;
    setNeed(selected);
    if (selected === "Web & Ecommerce") {
      conFields.forEach((f) => f.classList.remove("con-hidden"));
    } else if (
      selected === "Branding" ||
      selected === "Social Media" ||
      selected === "Digital Marketing" ||
      selected === "Brand Strategy"
    ) {
      for (const fields of conFields) {
        if (
          fields.classList.contains("budget") ||
          fields.classList.contains("improve")
        ) {
          fields.classList.remove("con-hidden");
        } else {
          fields.classList.add("con-hidden");
        }
      }
      setSite("N/A");
      setSiteType("N/A");
    } else {
      conFields.forEach((f) => f.classList.add("con-hidden"));
      setSite("N/A");
      setSiteType("N/A");
      setImprove("N/A");
      setBudget("N/A");
    }
  };

  //RESET ALL
  const resetForm = (form) => {
    form.reset();
    setName("");
    setEmail("");
    setNeed("Web & Ecommerce");
    setBrief("");
    setSite("");
    setImprove("");
    setSiteType("One-page website");
    setBudget("£0-500");
    setMessage("No message.");
    setBiscuits("no");
  };
  //SPAM
  const spamLabel = useRef(null);
  let spamFirst = useRef(null);
  let spamSecond = useRef(null);
  const [userAnswer, setUserAnswer] = useState("");
  useEffect(() => {
    spamFirst.current = Math.floor(Math.random() * 11);
    spamSecond.current = Math.floor(Math.random() * 11);
    spamLabel.current.textContent = `${spamFirst.current} + ${spamSecond.current}`;
  }, []);

  const submissionHandler = async (e) => {
    e.preventDefault();
    if (spamFirst.current + spamSecond.current !== Number(userAnswer)) {
      formRes.current.classList.add("fail");
      formRes.current.textContent = "You failed the spam protection.";
      return;
    }
    const data = {
      name: String(name),
      email: String(email),
      need: String(need),
      brief: String(brief),
      site: String(site),
      improve: String(improve),
      site_type: String(siteType),
      budget: String(budget),
      message: String(message),
      biscuits: String(biscuits),
    };
    formRes.current.textContent = "Loading...";
    formRes.current.classList.remove("success");
    formRes.current.classList.remove("fail");
    await axios
      .post("https://hyphencreative.co.uk/emails/contact-form", data)
      .then((res) => {
        formRes.current.classList.add(res.data.status);
        formRes.current.textContent = res.data.message;
        resetForm(e.target);
      })
      .catch(function (error) {
        console.log(error.toJSON());
        formRes.current.classList.add("fail");
        formRes.current.textContent =
          "Your message has not been sent, please try again!";
      });
  };

  const gif = useRef();
  const playGif = (e) => {
    e.target.style.pointerEvents = "none";
    gif.current.classList.add("playing");
    gif.current.src = BreakingBad;
    setTimeout(() => {
      gif.current.classList.remove("playing");
      e.target.style.pointerEvents = "all";
    }, 3940);
  };

  return (
    <div>
      <SEO
        title="Hyphen Creative | Contact Us | Derbyshire Design Company"
        description="Get in touch with us today to discuss your design project. We can't wait to hear from you!"
        keywords="Contact Hyphen Creative, Web Design Company, Design Company"
      />
      <section id="contact-hero">
        <div className="container">
          <div className="contact-hero">
            <div className="awards">
              <img
                width="212.77px"
                height="36.794px"
                src={ShopifyPartner}
                alt="Shopify Partner"
              />
              <img
                width="71px"
                height="71px"
                src={Top3}
                alt="Top 3 Website Designer 2021"
              />
            </div>
            <h1 className="bold">
              Great! You're ready <br />
              to say no <span className="light">to mediocre?</span>
            </h1>
          </div>
        </div>
      </section>
      <section id="contact-content">
        <div className="container">
          <div className="contact-content">
            <div className="contact-offices">
              <p className="p-lg">Our Offices</p>
              <div className="office">
                <p className="bold">Derby</p>
                <p>
                  <a
                    href="https://maps.app.goo.gl/vPwMfoHHj412brzv9"
                    target="_blank"
                    rel="noreferrer"
                  >
                    1 King Steet, <br />
                    Belper, Derby, <br />
                    DE56 1PP
                  </a>
                </p>
                <p>
                  <a href="tel:+441773825690">+44 (0) 1773 825 690</a>
                </p>
                <p>
                  <a href="mailto:info@hyphencreative.co.uk">
                    info@hyphencreative.co.uk
                  </a>
                </p>
                <p className="parking">
                  <a
                    href="https://maps.app.goo.gl/jUpu8q44mL8vgM3X8"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Paid Parking (2 min🚶)
                    <img width="30px" height="30px" src={Arrow} alt="Parking" />
                  </a>
                </p>
                <p className="parking">
                  <a
                    href="https://maps.app.goo.gl/MBLquJ7muAExpqGm6"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Free Parking (5 min🚶)
                    <img width="30px" height="30px" src={Arrow} alt="Parking" />
                  </a>
                </p>
              </div>
              <div className="office">
                <p className="bold">Manchester</p>
                <p>Coming Soon 👀</p>
                {/* <p>
                  <a href="tel:+44161825690">+44 (0) 0161 825 690</a>
                </p> */}
                <p>
                  <a href="mailto:info@hyphencreative.co.uk">
                    info@hyphencreative.co.uk
                  </a>
                </p>
              </div>
            </div>
            <div className="contact-form">
              <div className="intro">
                <p>
                  Ready to launch your new product? <br />
                  Want a redesign of your current website? <br />
                  Or maybe you have a half-baked idea that needs some help…
                </p>
                <img
                  className="we-got-you"
                  width="257.68px"
                  height="51px"
                  src={WeGotYou}
                  alt="We Got You"
                  onMouseEnter={playGif}
                />
                <img
                  height="225px"
                  width="323.594"
                  className="gif"
                  src={BreakingBad}
                  alt="Walter Quote"
                  ref={gif}
                />
              </div>
              <h2>Let's discuss! 👋</h2>
              <p>We'd love to meet you, as long as you bring the biscuits!</p>
              <div className="form">
                <form onSubmit={(e) => submissionHandler(e)}>
                  <div className="form-row-split">
                    <div>
                      <label>
                        Your Name*
                        <input
                          autoComplete="true"
                          required
                          name="full_name"
                          type="text"
                          placeholder="First & Last"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </label>
                    </div>
                    <div>
                      <label>
                        Your Email*
                        <input
                          autoComplete="true"
                          required
                          name="email"
                          type="email"
                          placeholder="Your Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="form-row-split">
                    <div>
                      {/* <label>
                        What do you need?
                        <input
                          required
                          name="need"
                          type="text"
                          placeholder="Web Design"
                          onChange={(e) => setNeed(e.target.value)}
                        />
                      </label> */}
                      <label>
                        What do you need?*
                        <select
                          required
                          name="need"
                          onChange={(e) => setConditionalNeed(e)}
                        >
                          <option value="Web & Ecommerce">
                            Web & Ecommerce
                          </option>
                          <option value="Graphic Design">Graphic Design</option>
                          <option value="Branding">Branding</option>
                          <option value="Photography">Photography</option>
                          <option value="Packaging">Packaging</option>
                          <option value="Social Media">Social Media</option>
                          <option value="Digital Marketing">
                            Digital Marketing
                          </option>
                          <option value="Brand Strategy">Brand Strategy</option>
                          <option value="Other">Other</option>
                        </select>
                      </label>
                    </div>
                    <div>
                      <label>
                        Do you have a brief?*
                        <input
                          required
                          name="brief"
                          type="text"
                          placeholder="Yes!"
                          onChange={(e) => setBrief(e.target.value)}
                        />
                      </label>
                    </div>
                  </div>
                  <p className="p-lg">Great! Let's get into it...</p>
                  <div className="form-row-conditional">
                    <div className="site">
                      <label>
                        Do you have an existing site?
                        <input
                          name="site"
                          type="text"
                          placeholder="Website URL"
                          onChange={(e) => setSite(e.target.value)}
                        />
                      </label>
                    </div>
                    <div className="site-type">
                      <label>
                        What type of site do you require?
                        <select
                          name="site_type"
                          onChange={(e) => setSiteType(e.target.value)}
                        >
                          <option value="One-page website">
                            One-page website
                          </option>
                          <option value="Brochure website">
                            Brochure website
                          </option>
                          <option value="Ecommerce website">
                            Ecommerce website
                          </option>
                          <option value="Other website">Other website</option>
                        </select>
                      </label>
                    </div>
                    <div className="improve">
                      <label>
                        What do you want to improve?
                        <input
                          name="improve"
                          type="text"
                          placeholder="Design"
                          onChange={(e) => setImprove(e.target.value)}
                        />
                      </label>
                    </div>
                    <div className="budget">
                      <label>
                        Do you have a budget in mind?
                        <select
                          name="budget"
                          onChange={(e) => setBudget(e.target.value)}
                        >
                          <option value="£0-500">£0-500</option>
                          <option value="£500-1500">£500-1500</option>
                          <option value="£1500-3000">£1500-3000</option>
                          <option value="£3000-5000">£3000-5000</option>
                          <option value="£5000-7500">£5000-7500</option>
                          <option value="£10000+">£10000+</option>
                        </select>
                      </label>
                    </div>
                  </div>
                  <div className="form-row-full">
                    <div>
                      <label>
                        Additional Notes or useful info...
                        <textarea
                          rows="8"
                          name="message"
                          placeholder="Tell us anything we might find useful about you or the the brand..."
                          onChange={(e) => setMessage(e.target.value)}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="form-row-full biscuits">
                    <p>
                      Finally, and most importantly, will you bring biscuits?
                    </p>
                    <div onChange={(e) => setBiscuits(e.target.value)}>
                      <label>
                        <input
                          type="radio"
                          name="biscuits"
                          value="yes"
                          checked={biscuits === "yes"}
                          readOnly
                        />
                        Yes, I'm nice...
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="biscuits"
                          value="no"
                          checked={biscuits === "no"}
                          readOnly
                        />
                        No biscuits for you!
                      </label>
                    </div>
                  </div>
                  <div className="form-row-split">
                    <div>
                      <label>
                        What is: <span ref={spamLabel}></span>?*
                        <input
                          required
                          name="spam_protect"
                          type="text"
                          placeholder="6"
                          onChange={(e) => setUserAnswer(e.target.value)}
                        />
                      </label>
                    </div>
                    <div></div>
                  </div>
                  <button className="blue-btn" type="submit">
                    Send Message
                  </button>
                </form>
                <p className="form-sent-status" ref={formRes}></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;
